import { useEffect, useState, useCallback } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import VimeoThumbnail from "./snippets/vimeo_thumbnail";
import AnyTextAnimation from "./snippets/any_text_animation";
import axios from 'axios';


function Index(props) {



  /* svgs */
  const svg_x = <svg xmlns="https://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="M6.062 15 5 13.938 8.938 10 5 6.062 6.062 5 10 8.938 13.938 5 15 6.062 11.062 10 15 13.938 13.938 15 10 11.062Z"/></svg>
  const svg_location = <svg xmlns="https://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="M10 15.917q-2.958-2.188-4.396-4.229-1.437-2.042-1.437-4.021 0-1.479.521-2.605.52-1.124 1.364-1.874.844-.75 1.886-1.136Q8.979 1.667 10 1.667q1.042 0 2.073.385 1.031.386 1.875 1.136.844.75 1.364 1.874.521 1.126.521 2.605 0 1.979-1.437 4.021-1.438 2.041-4.396 4.229Zm0-6.667q.708 0 1.208-.5t.5-1.208q0-.709-.5-1.209-.5-.5-1.208-.5t-1.208.5q-.5.5-.5 1.209 0 .708.5 1.208t1.208.5Zm-5.833 9.083v-1.75h11.666v1.75Z"/></svg>;
  const svg_telephon = <svg xmlns="https://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="M16.646 17.5q-2.729 0-5.281-1.188-2.553-1.187-4.521-3.156-1.969-1.968-3.156-4.521Q2.5 6.083 2.5 3.354q0-.354.26-.604.261-.25.615-.25h3.458q.292 0 .511.188.218.187.26.479l.584 2.979q.041.271-.021.5-.063.229-.229.396L5.917 9.104q.791 1.417 2.093 2.729 1.302 1.313 2.865 2.25l1.958-2q.209-.208.459-.281.25-.073.541-.031l3 .625q.292.042.479.26.188.219.188.511v3.458q0 .354-.25.615-.25.26-.604.26Z"/></svg>;
  const svg_mail = <svg xmlns="https://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="M3.417 16.667q-.729 0-1.24-.511-.51-.51-.51-1.239V5.083q0-.729.51-1.239.511-.511 1.24-.511h13.166q.729 0 1.24.511.51.51.51 1.239v9.834q0 .729-.51 1.239-.511.511-1.24.511ZM10 10.938l6.583-4.105v-1.75L10 9.208 3.417 5.083v1.75Z"/></svg>;

  const svg_left = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/></svg>;
  const svg_right = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="20" width="20"><path d="m8 15-1.062-1.062L10.875 10 6.938 6.062 8 5l5 5Z"/></svg>;


  /* Video list */
  /* Test video: 312565441 */
  const [videoList, setVideoList] = useState([
    /* '312565441', */
    '655272770',
    '655273384',
    '655273109',
    '651037589',
    '651036411',
    '651035544',
    '651033372',
    '651032171',
    '651030958',
    '651030227',
    '655273302',
    '658571889',
  ]);

  const [videoDescriptionsList, setVideoDescriptionsList] = useState([
    /* '312565441', */
    "Període: Edat Mitjana <br/> Obra: <em>Trobadors</em> <br/> Compositor: Guillem de Bergadà (1138 - 1196) <br/> Intèrpret/s: Antoni Madueño, veu <br/> Tipologia: Solista vocal <br/> Localització: Castell de Miravet",
    "Període: Renaixement <br/> Obra: <em>Motets</em> <br/> Compositor: Mateu Fletxa (1481 – 1553) <br/> Intèrpret/s: La Caravaggia, conjunt de música antiga, i Qvinta Essençia, grup vocal <br/> Tipologia: Ensemble instrumental i vocal Localització: Monestir de Sant Llorenç a Guardiola de Berguedà",
    "Període: Renaixement <br/> Obra: <em>Libera me</em> <br/> Compositor: Josep Reig (1595 - 1674) <br/> Intèrpret/s: Companyia Musical, conjunt de música vocal amb acompanyament instrumenal <br/> Tipologia: Ensemble vocal <br/> Localització: Castell de Cardona (interior)",
    "Període: Barroc <br/> Obra: <em>Tiento...</em> <br/> Compositor: Gabriel Manalt (1657 - 1687) <br/> Intèrpret/s: Jordi Vergés, orgue <br/> Tipologia: Solista instrumental <br/> Localització: Catedral de Tarragona (Vergés és l'organista de la Catedral)",
    "Període: Barroc <br/> Obra: <em>Contradanses i minuets</em> <br/> Compositor: Carles Baguer (1768 - 1808) <br/> Intèrpret/s: Vespres d'Arnadí, conjunt de música antiga <br/> Tipologia: Ensemble instrumental (cambra) <br/> Localització: La Seu Vella (Lleida)",
    "Període: Galant - classicisme <br/> Obra: <em>Sonata</em> <br/> Compositor: Cantallops (S. XVIII) <br/> Intèrpret/s: Dani Espasa, clave <br/> Tipologia: Solista instrumental <br/> Localització: Palau Moja - Gran saló (Barcelona)",
    "Període: Romanticisme <br/> Obra: <em>Romança i caprici</em> <br/> Compositor: Joan Manén (1883 - 1971) <br/> Intèrpret/s: Kalina Machuta, violí i Daniel Blanch, piano <br/> Tipologia: Duet instrumental, cambra <br/> Localització: Sant Pere de Rodes (interior)",
    "Període: Classicisme <br/> Obra: <em>Suite</em> <br/> Compositor: Gaspar Cassadó (1897 - 1966) <br/> Intèrpret/s: Mariona Camats, violoncel <br/> Tipologia: Solista instrumental <br/> Localització: Museu de la Ciència i la Tècnica de Catalunya a Terrassa",
    "Període: Nacionalisme <br/> Obra: <em>Trio</em> <br/> Compositor: Francesc Civil (1895 - 1990) <br/> Intèrpret/s: Trio Fortuny, Joel Bardolet, violí I Pau Codina, cello I, Marc Heredia, piano <br/> Tipologia: Conjunt instrumental, cambra <br/> Localització: Museu d'Art de Girona",
    "Període: Segle XX - Cançó <br/> Obra: <em>Cicle Combat del Somni · Cançons</em> <br/> Compositor: Frederic Mompou i Dencausse (1893 - 1987), Eduard Toldrà i Soler (1895 - 1962), Rosa Puig i Llonch (Terrassa, 1901-1999) <br/> Intèrpret/s: Anna Alàs i Jové, mezzosoprano, Alexander Fleischer, piano <br/> Tipologia: Lied català <br/> Localització: Recinte Modernista de Sant Pau a Barcelona",
    'Període: Segle XX <br/> Obra: <em>Els focs de Sant Joan (ballet)</em> <br/> Compositor: Robert Gerhard i Ottenwaelder (1896 - 1970) <br/> Intèrpret/s: Miquel Villalba, piano i la companyia Ballet Contemporani de Catalunya <br/> Tipologia: Dansa amb música en directe (piano) <br/> Localització: Saló Oval del Palau Nacional - MNAC (Barcelona)',
    'Període: Segle XX <br/> Obra: <em>Solos</em> <br/> Compositor: Joaquim Homs i Oller (1906 - 2003), Robert Gerhard i Ottenwaelder (1896 - 1970) <br/> Intèrpret/s: Elisabet Franch, flauta travessera <br/> Tipologia: Solo instrumental <br/> Localització: Sant Climent de Taüll (Vall de Boí)',
  ]);

  
  



  /* Header */
  const header_src = 'home_video.mp4';
  const header_title = '                         adep.cat';
  const header_subtitle = '                                                                                                  Suport als creadors i a la recuperació patrimonial';




  /* Support Text */
  const support_title = "Patrimoni 360";
  const support_subtitle = "Una aproximació al patrimoni musical i arquitectònic català";
  const support_text = "Amb l'objectiu de <strong>difondre el patrimoni musical català</strong>, entès com un dels factors més representatius dels mil anys d’existència cultural de Catalunya, i cercant la seva vinculació amb elements clau del <strong>patrimoni arquitectònic del país</strong>, l'Associació per a la Divulgació, l'Ensenyament i la Promoció de la Música Catalana · ADEP.CAT desplega aquest projecte de dotze videoclips enregistrats en espais patrimonials que donen una visió atractiva del patrimoni entès des d'una perspectiva material (els espais monumentals) i immaterial (la música) i que s'adreça al públic en general. <br/> <br/> El projecte, que compta amb el patrocini del Departament de Cultura de la Generalitat de Catalunya, ha evolucionat fins a <strong>la sèrie final de dotze clips que reflecteixen els diferents períodes i tendències artístiques presents en el patrimoni musical català</strong>. Això és des de l’Edat Mitjana fins a les primeres avantguardes (segle XX). Amb l'assessorament musicològic de l'editorial FICTA en relació als períodes medieval i renaixentista, s'han cercat els intèrprets més adients per a cada estil musical (formacions de petit format, de fins a 10 músics, atès que <strong>la producció i l’enregistrament dels clips s'ha realitzat en plena pandèmia de la Covid-19</strong>) i, sempre que ha estat possible, s'han vinculat els enregistraments a unes localitzacions d’entorn físic que dialoguin amb cadascuna de les estètiques musicals i n’assegurin una contextualització adreçada a una millor comprensió de les obres.";
  const support_text_list = null;
  /* [
    "Incentivar la creació d'iniciatives destinades a promoure el so dels instruments tradicionals catalans, en especial els que es vinculen a la formació 'cobla' (flabiol, tenora, tible i fiscorn), tot i que no exclusivament, dins i fora del territori català.",
    "Potenciar la creativitat dels músics catalans afavorint la producció d'obres originals.",
    "Donar suport a l'estudi, recerca i investigació desenvolupada per especialistes que treballin a partir de materials de la cultura d'arrel (sigui o no catalana).",
    "Promoure iniciatives destinades a millorar la formació dels instrumentistes catalans (en format beca, masterclass, stage , cursos intensius, etc.).",
    "Donar suport als joves artistes emergents, estiguin o no vinculats amb la cultura popular i tradicional.",
    "Articular propostes destinades a la difusió musical en formats diversos: suport a la programació de concerts en sales de format i aforament divers, assaigs oberts, tallers participatius...",
    "Col·laborar amb altres associacions, entitats, artistes... que comparteixin interessos afins per esdevenir un projecte cultural integrador.",
    "Internacionalitzar el so dels instruments catalans així com l'obra dels creadors del nostre país, sigui quin sigui l'estil o disciplina que desenvolupin.",
  ] */


  /* Media Text */
  const media_title = "Mèdia"

  /* Contact Text */
  const contact_title = "Contacte";
  const contact_subtitle = "Associació per a la Divulgació, l'Ensenyament i la Promoció de la Música Catalana."
  const contact_add = "Bruguera 195, 2n 3a 08370 Calella - Barcelona";
  const contact_tel = "+34 937 670 740 / +34 649 935 371";
  const contact_email = "info@adep.cat";
  const fet_per = "Fet per:";
  const contact_support = "Amb el suport:";
  const contact_colaboration = "Hi col·labora:";









  const [thumbnailVideoID, setThumbnailVideoID] = useState(videoList && videoList[9]? videoList[9] : '');
  const [thumbnailVideo, setThumbnailVideo] = useState(null);

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [modalVideoInfo, setModalVideoInfo] = useState(null);
  const [modalVideoPosition, setModalVideoPosition] = useState(0);
  
  const [scrollValue, setScrollValue] = useState(0);

  
  useEffect(() => {
    var num = 0;
    if (videoList && modalVideoInfo) {
      videoList?.map(element => {
        num++;
        if (parseInt(element) === modalVideoInfo.id) {
          setModalVideoPosition(num);
        }
      });
    } else {
      setModalVideoPosition(num);
    }
  }, [modalVideoInfo]);


  useEffect(() => {
    axios.get(`https://vimeo.com/api/v2/video/${thumbnailVideoID}.json`).then(res => {
      if (res && res.data && res.data[0]) {
        setThumbnailVideo(res.data[0]);
      } else {
        console.log(' ')
        console.log('ERROR')
      }
    })
  }, []);


  /* useEffect(() => {
    setScrollValue(window.scrollY)
    console.log(window.scrollY)
  }, [window.scrollY]);
   */
  const handleScroll = useCallback((a) => {
    let scrollHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
    
    var userScroll = window.scrollY;
    var maxScroll = scrollHeight-937;
    var scrollOercentage = ((userScroll/maxScroll)*100).toFixed(2) /* (Math.round(((userScroll/maxScroll)*100) * 10) / 10) */
    setScrollValue(scrollOercentage);

  }, [])

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = window
    div.addEventListener("scroll", handleScroll)
  }, [handleScroll])






  /* Title animation */
  const sentance = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.08,
      }
    }
  }
  const sentanceFast = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.02,
      }
    }
  }
  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  }
  const letterShadow = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 0.5, y: 0 }
  }








  /* Any text animation */
  const anySentance = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.08,
      }
    }
  }
  const anyLetter = {
    hidden: { opacity: 0, y: 50 },
    visible: (custom) => ({ 
      opacity: 1, 
      y: 0,
      transition: {
        delay: 2 + (custom * 0.05),
      }
    })
  }




  /* Video animations */
  const videoBox = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.3,
      }
    }
  }
  const videoItem = {
    hidden: { 
      opacity: 0, 
      y: 50,
      transition: {
        delay: 3,
      }
    },
    visible: (custom) => ({ 
      opacity: 1, 
      y: 0,
      transition: {
        delay: 2 + (custom * 0.2),
      }
    })
  }





  function changeModalPage(newItem) {

    console.log(' ')
    console.log(newItem)
    console.log(modalVideoPosition)
    console.log(modalVideoPosition+newItem)
    console.log(videoList[(modalVideoPosition+newItem)-1])

    if (videoList[(modalVideoPosition+newItem)-1]) {
      setShowContentModal(false);
      axios.get(`https://vimeo.com/api/v2/video/${videoList[(modalVideoPosition+newItem)-1]}.json`).then(res => {

        if (res && res.data && res.data[0]) {
          setModalVideoInfo(res.data[0]);
        } else {
          console.log(' ')
          console.log('ERROR')
        }

      }).then((res) => {
        setTimeout(function(){ setShowContentModal(true); }, 300);
      })
    }

  }




  return thumbnailVideo && (

    <div className="bg-white">
      
      <AnimatePresence>

        <div className="fixed top-0 left-0 w-full bg-transparent h-1 z-50">
          <div className="bg-yellow-400 h-1 rounded-r-full" style={{ width: scrollValue+'%' }}></div>
        </div>

        <motion.div 
          initial={{ y: '-100%' }}
          animate={{ y: '0%' }}
          exit={{ y: '-100%' }}
          transition={{ ease: "anticipate", duration: 1.5 }}
          className="video-page-content"
          key="a"
        >


          {/* Video */}
          <div className='video-container'>
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", delay: 1.5, duration: 0.5 }}
              className="w-full"
              key="b"
            >
              <video className="bg-video" autoPlay loop muted poster={ thumbnailVideo && thumbnailVideo?.thumbnail_large? thumbnailVideo.thumbnail_large : '' }>
                <source src={header_src} /* src="home_video.mp4" */ type="video/mp4" style={{ zIndex: '-1', border: 0 }}/>
              </video>
            </motion.div>


            <div className='video-container-transition'>
              <div style={{ marginLeft: '7vw', marginTop: '7vw' }}>
                <motion.h1 
                  variants={sentance}
                  initial={'hidden'}
                  animate={'visible'}
                  className="video-title-shadow"
                  key="c"
                >
                  {header_title.split('').map((char, index) => {
                    return (
                      <motion.span key={char + "-d-" + index} variants={letterShadow}>
                        {char}
                      </motion.span>
                    );
                  })}
                </motion.h1>

                <motion.h1 
                  variants={sentance}
                  initial={'hidden'}
                  animate={'visible'}
                  className="video-title"
                  key="e"
                >
                  {header_title.split('').map((char, index) => {
                    return (
                      <motion.span key={char + "-f-" + index} variants={letter}>
                        {char}
                      </motion.span>
                    );
                  })}
                </motion.h1>
              </div>
              <div style={{ marginLeft: '7vw' }}>
              <motion.h1 
                  variants={sentanceFast}
                  initial={'hidden'}
                  animate={'visible'}
                  className="video-subtitle-shadow"
                  key="g"
                >
                  {header_subtitle.split('').map((char, index) => {
                    return (
                      <motion.span key={char + "-h-" + index} custom={index} variants={letter}>
                        {char}
                      </motion.span>
                    );
                  })}
                </motion.h1>

                <motion.h1 
                  variants={sentanceFast}
                  initial={'hidden'}
                  animate={'visible'}
                  className="video-subtitle"
                  key="i"
                >
                  {header_subtitle.split('').map((char, index) => {
                    return (
                      <motion.span key={char + "-j-" + index} custom={index} variants={letter}>
                        {char}
                      </motion.span>
                    );
                  })}
                </motion.h1>
              </div>


              <div className='video-page-transition'></div>
            </div>
          </div>






          {/* Content */}
          <div className='content'>


            {/* Who we are */}
            <div className='px-1 md:px-2 xl:px-4'>
              {/* <AnyTextAnimation text={support_title} textClass={"responsive-subtitle"} textBoxVariant={anySentance} textVariant={anyLetter} /> */}
              
              <motion.div 
                variants={anySentance}
                initial={'hidden'}
                animate={'visible'}
                className="basic-separation-top"
                style={{ marginTop: '5vw' }}
                key="k"
              >
                <motion.p key="l" custom={0} variants={anyLetter} className="responsive-subtitle">
                  {support_title}
                </motion.p>
              </motion.div>

              <motion.div 
                variants={anySentance}
                initial={'hidden'}
                animate={'visible'}
                className="basic-separation-bottom"
                key="n"
              >
                <motion.p key="l" custom={0} variants={anyLetter} className="responsive-subtitle-small">
                  {support_subtitle}
                </motion.p>
              </motion.div>
              


              <div>
                <motion.div 
                  variants={anySentance}
                  initial={'hidden'}
                  animate={'visible'}
                  className="mb-10"
                  key="n"
                >
                  <motion.p 
                    custom={1} 
                    variants={anyLetter} 
                    className="responsive-text" 
                    dangerouslySetInnerHTML={{__html: support_text? support_text : ''}}
                    key="p"
                  >
                  </motion.p>
                </motion.div>



                <motion.ul 
                  variants={anySentance}
                  initial={'hidden'}
                  animate={'visible'}
                  key="q"
                >
                  {support_text_list?.map((char, index) => {
                    return (
                      <motion.li key={"r-" + index} custom={index} variants={anyLetter} className="responsive-text">
                        {char}
                      </motion.li>
                    );
                  })}
                </motion.ul>
              </div>
            </div>




            {/* Media */}

            <div className='basic-separation px-1 md:px-2 xl:px-4'>
              <motion.div 
                variants={anySentance}
                initial={'hidden'}
                animate={'visible'}
                key="s"
              >
                <motion.p key="t" custom={0+4} variants={anyLetter} className="responsive-subtitle">
                  {media_title}
                </motion.p>
              </motion.div>
            </div>

            <motion.div 
              variants={videoBox}
              initial={'hidden'}
              animate={'visible'}
              className='flex flex-wrap items-start w-full space-y-10 md:space-y-0'
              key="q"
             >
              {videoList?.map((video, index) => {
                  return (
                    <motion.div 
                      key={video + '-r-' + index}
                      custom={index+3}
                      variants={videoItem}
                      className="w-full md:w-1/2 p-1 md:p-4 xl:p-8"
                    >
                      <VimeoThumbnail videoID={video} openVideoModal={openVideoModal} setOpenVideoModal={setOpenVideoModal} setShowContentModal={setShowContentModal} setModalVideoInfo={setModalVideoInfo} videoDescription={videoDescriptionsList[index]? videoDescriptionsList[index] : null} />
                    </motion.div>
                  );
                })}
            </motion.div>



            <motion.div 
              variants={anySentance}
              initial={'hidden'}
              animate={'visible'}
              className='flex flex-wrap items-start w-full space-y-10 md:space-y-0'
              key="m"
             >
              <motion.a 
                key={'m-1'}
                custom={45}
                variants={anyLetter}
                href='https://newcat.eu ' 
                target="_blank" 
                className='def-transition a-no-styles border-[1px] border-gray-700 w-full flex justify-center items-center text-black active:text-black mx-1 md:mx-4 xl:mx-8 mt-8 p-3 bg-gray-50 hover:bg-gray-100 hover:cursor-pointer hover:scale-[102%] shadow-xl md:shadow-sm hover:shadow-xl' 
                onClick={ () => {  }}
                > 
                  Altres projectes destacats {svg_right}
              </motion.a>
            </motion.div>




            {/* Contact */}
            <div className='px-1 md:px-2 xl:px-4'>

              {/* <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", delay: 5.5, duration: 0.5 }}
                className="basic-separation"
              >      
                <motion.div 
                  variants={anySentance}
                  initial={'hidden'}
                  animate={'visible'}
                >
                  <motion.p custom={0} variants={anyLetter} className="responsive-subtitle">
                    {contact_title}
                  </motion.p>
                </motion.div>
              </motion.div> */}

              <motion.div 
                variants={anySentance}
                initial={'hidden'}
                animate={'visible'}
                className="basic-separation"
              >
                <motion.p custom={54} variants={anyLetter} className="responsive-subtitle">
                  {contact_title}
                </motion.p>
              </motion.div>


            
              <div className='w-full flex justify-between items-start flex-wrap space-y-14 xl:space-y-0 xl:flex-nowrap'>
                <div className='flex justify-between flex-wrap xl:flex-nowrap'>

                  <div className="w-full">
                    <motion.div 
                      variants={anySentance}
                      initial={'hidden'}
                      animate={'visible'}
                      className="mt-1"
                    >
                      <motion.p custom={56} variants={anyLetter} className="responsive-text">
                        {contact_subtitle}
                      </motion.p>
                    </motion.div>

                    <br></br>

                    <motion.div 
                      variants={anySentance}
                      initial={'hidden'}
                      animate={'visible'}
                    >
                      <motion.p custom={58} variants={anyLetter} className="flex items-center responsive-text-small text-gray-500 mb-2">
                        <span className='mr-2'> {svg_location} </span> 
                        {contact_add}
                      </motion.p>
                    </motion.div>

                    <motion.div 
                      variants={anySentance}
                      initial={'hidden'}
                      animate={'visible'}
                    >
                      <motion.p custom={60} variants={anyLetter} className="flex items-center responsive-text-small text-gray-500 mb-2">
                        <span className='mr-2'> {svg_telephon} </span> 
                        {contact_tel}
                      </motion.p>
                    </motion.div>

                    <motion.div 
                      variants={anySentance}
                      initial={'hidden'}
                      animate={'visible'}
                    >
                      <motion.p custom={62} variants={anyLetter} className="flex items-center responsive-text-small text-gray-500 mb-2">
                        <span className='mr-2'> {svg_mail} </span> 
                        <a href={`mailto:${contact_email}`}>{contact_email}</a>
                      </motion.p>
                    </motion.div>
                  </div>



                  <motion.div 
                    variants={anySentance}
                    initial={'hidden'}
                    animate={'visible'}
                    className="w-full mr-0 mt-10 xl:mt-0 xl:ml-10"
                  >
                    <motion.div custom={64} variants={anyLetter} className="w-full">
                      <p className='responsive-text-small text-gray-500 mb-2'>{fet_per}</p>
                      <div className='flex items-center w-60 overflow-hidden'>
                        <img src="logo adep.jpg" className="object-cover w-20 h-20" />
                      </div>
                    </motion.div>

                    <motion.div custom={66} variants={anyLetter} className="w-full mt-4">
                      <p className='responsive-text-small text-gray-500 mb-2'>{contact_support}</p>
                      <div className='flex items-center w-60 overflow-hidden'>
                        <img src="logo_cultura_h3.gif" className="object-cover w-full h-full" />
                      </div>
                    </motion.div>
                  </motion.div>


                  <br></br>

                  
                  {/* <motion.div 
                    variants={anySentance}
                    initial={'hidden'}
                    animate={'visible'}
                  >
                    <motion.div custom={66} variants={anyLetter}>
                      <p className='responsive-text-small text-gray-500'>{contact_colaboration}</p>
                      <div className='flex items-center w-40 overflow-hidden'>
                        <img src="logo_caixa_300.png" className="object-cover w-full h-full" />
                      </div>
                    </motion.div>
                  </motion.div> */}

                </div>

                {/* <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: "easeInOut", delay: 5.5, duration: 0.5 }}
                  className='w-full'
                >      
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5965.672735429697!2d2.663697!3d41.61605!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12bb3906af60d979%3A0xa6e3ea52cfb4c879!2sCarrer%20de%20la%20Bruguera%2C%20195%2C%203a%2C%2008370%20Calella%2C%20Barcelona!5e0!3m2!1sen!2ses!4v1657015813624!5m2!1sen!2ses" 
                    height="450" 
                    style={{ border: 0, width: '100%' }}
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                  >
                  </iframe>
                </motion.div> */}

              </div>

            </div>
            







          </div>
        </motion.div>




        {/* MODAL */}
        { openVideoModal && modalVideoInfo? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.2 }}
            className='modal-all'
            key="modal-all"
            onClick={ (event) => { if (event?.target?.className?.includes("modal-all") || event?.target?.className?.includes("modal-fake")) { setOpenVideoModal(false); setShowContentModal(false) } }}
          >


            <AnimatePresence>


              {showContentModal && 

                <motion.div
                  initial={{ y: '300%' }}
                  animate={{ y: '0%' }}
                  exit={{ y: '-300%' }}
                  transition={{ ease: "easeInOut", duration: 0.3 }}
                  className="modal-fake flex items-stretch justify-center w-full"
                  key="modal-box"
                >
                  
                  <button type='button' disabled={modalVideoPosition > 1? false : true } className='modal-button' onClick={ () => { changeModalPage(-1) }}> {svg_left} </button>

                  <div className='modal-content'>
                    <div className='w-full flex items-center justify-between space-x-4 pl-4 overflow-hidden bg-white'>
                      <h4 className='responsive-subtitle-small overflow-ellipsis truncate whitespace-nowrap'></h4>
                      <button type='button' className='def-transition bg-gray-200 hover:bg-red-400 p-2 z-50 hover:rotate-90' onClick={ () => { setOpenVideoModal(false) }}> {svg_x} </button>
                    </div>
      
                    <div className='modal-video-info'>
                      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }} className="bg-black">
                        <iframe 
                          src={`https://player.vimeo.com/video/${modalVideoInfo?.id}?h=59704ace79&title=0&byline=0&portrait=0&autoplay=1"`}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} 
                          frameBorder="0" 
                          allow="autoplay; fullscreen; picture-in-picture" 
                          allowFullScreen
                        >
                        </iframe>
                      </div>

                      <div className='p-4'>
                        <p className='responsive-subtitle-small px-1' dangerouslySetInnerHTML={{__html: modalVideoInfo?.title? modalVideoInfo?.title : ''}}></p>
                        { modalVideoInfo?.description && modalVideoInfo?.description != '' && <div>
                          <hr className='my-4 border-gray-200'></hr>
                          <p className='responsive-text px-1' dangerouslySetInnerHTML={{__html: modalVideoInfo?.description? modalVideoInfo?.description : ''}}></p>
                        </div>}
                      </div>
                    </div>
                  </div>

                  <button type='button'  disabled={modalVideoPosition < videoList.length? false : true } className='modal-button' onClick={ () => { changeModalPage(+1) }}> {svg_right} </button>

                </motion.div>

              }


            </AnimatePresence>


          </motion.div>
        ) : null }


      </AnimatePresence>
    </div>
  );
}


export default Index